import axios from "axios";
 
// // Prod BE URL
// const API_URL = "https://webapp.app.jobizo.com/api/graph-api";
 
// Pre-Prod BE URL
// const API_URL = "https://web-preprod.jobizo.com/api/graph-api";
// const API_URL = "http://localhost:3001/api/graph-api";
 
// Staging BE URL
//const API_URL = "https://8a0a-59-144-164-156.ngrok-free.app/api/graph-api";
const API_URL = "https://api.jobizo.com/api/graph-api";
 
const graphqlRequest = async (query, variables = {}) => {
  try {
    const response = await axios.post(API_URL, {
      query,
      variables,
    });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
 
export default graphqlRequest;
