import React, {useState, useEffect} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { useSelector } from "react-redux";
import "./Layout.scss";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";

import {
  isJobMatch,
  setOpenJobMatches,
  setModel,
  setActiveTab,
} from "../../redux/userReducer";
import JobOptionModel from "../../components/ShiftLogs/JobOptionModel";
import JobMatches from "../../components/Common/JobMatches/JobMatches";

import useWindowDimensions from "../../components/Common/WindowDimention/windowDimention";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Layout(props) {
  let { myModel, isJobMatch, recommendedUser } = useSelector(
    (state) => state.user
  );
  let dispatch = useDispatch();
  const handleClose = () => dispatch(setModel());
  const handleJobMatchClose = () => dispatch(setOpenJobMatches());

  let navigate = useNavigate();

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const { height, width } = useWindowDimensions();

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  return (
    <div className="layout">
      <div className="navbar-container">
        <Navbar />
      </div>

      {width > 480 ? (
         <div className="layout-wrapper">
         <div className="sidebar-container">
           <Sidebar />
           <p
             style={{
               color: "#fff",
               fontFamily: "cursive",
               textAlign: "center",
               fontSize: "12px",
             }}
           >
 
            Version 2.0.14 - Pre-Prod
 
           </p>
         </div>
         <div className="outlet-container">
           <Outlet />
         </div>
       </div>
      ): (
        <div className="layout-wrapper">
        <div className="sidebar-container">
          <Sidebar />
          {/* <p
            style={{
              color: "#fff",
              fontFamily: "cursive",
              textAlign: "center",
              fontSize: "12px",
            }}
          >

            Version 2.0.13 - Pre-Prod

          </p> */}
        </div>
        <div className="outlet-container">
          <Outlet />
        </div>
      </div>
      )}
 
     

      {/* this model will open when user wants to select one out of two job option */}

      <Modal
        open={myModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <JobOptionModel />
      </Modal>

      {/* this model will open when user requirement meets with we have in db */}
      <Modal
        open={isJobMatch}
        onClose={() => {
          handleJobMatchClose();
          dispatch(
            setActiveTab({ index: 3, locumActive: 1, LocumJobToggle: true })
          );
          navigate("/locum/open-jobs");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <JobMatches />
      </Modal>
    </div>
  );
}

export default Layout;
